<template>
  <div class="pages">
    <div class="header">
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="changeVersionlist"
        allowClear
        size="small"
        style="margin: 5px; width: 150px"
        v-model="searchParams.kindId"
        placeholder="请选择大币种"
      >
        <a-select-option
          v-for="items of biglist"
          :key="items.kindId"
          :value="items.kindId"
          >{{ items.kindName }}</a-select-option
        >
      </a-select>
      <!-- 导出按钮 -->
      <a-button
        @click="exportData()"
        type="primary"
        size="small"
        style="margin-left: 10px"
        >导出数据</a-button>
    </div>
    <div class="body">
      <a-table
        @change="changePage"
        :pagination="pagination"
        :columns="columns"
        :data-source="list"
        :rowKey="(record, n) => record.id || n"
      >
      </a-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      biglist:[], //大币种列表
      searchParams: {},
      columns: [
        { title: "银元名称", dataIndex: "versionName" },
        {
          title: "评级公司",
          dataIndex: "type",
        },
        {
          title: "分数名称",
          dataIndex: "scoreName",
        },
        {
          title: "昨日价格",
          dataIndex: "price",
        },
      ],
      pagination: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
    };
  },
  created() {
    this.getSelectList();
  },
  methods: {
    // 
    async getYesterdayPrice(){
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoodsStatistics/getYesterdayPrice",{params:{
          kindId: this.searchParams.kindId
        }}
      );
      this.list = res.data
    },
    exportData() {
      // 列标题
      const jsonData = this.list;
      // let str = '<tr><td colspan="4"></td></tr>';
      let str = "";
      str +=
        "<tr style='text-align:center'><td>银元名称</td><td>评级公司</td><td>分数名称</td><td>昨日白币价格</td></tr>";
      // 循环遍历，每行加入tr标签，每个单元格加td标签
      for (let i = 0; i < jsonData.length; i++) {
        str += `<tr style='text-align:center'><td>${jsonData[i]["versionName"] + "\t"}</td>`+
          `<td>${jsonData[i]["type"] + "\t"}</td>`+
          `<td>${jsonData[i]["scoreName"] + "\t"}</td>`+
          `<td>${jsonData[i]["price"] + "\t"}</td>`+
        `</tr>`;
      }
      // Worksheet名
      const worksheet = "斗泉";
      const uri = "data:application/vnd.ms-excel;base64,";

      // 下载的表格模板数据
      const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" 
        xmlns:x="urn:schemas-microsoft-com:office:excel" 
        xmlns="http://www.w3.org/TR/REC-html40">
        <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
        <x:Name>${worksheet}</x:Name>
        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
        </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
        </head><body><table>${str}</table></body></html>`;
      // 下载模板
      const coin = this.biglist.find(el=>{
        return this.searchParams.kindId == el.kindId
      })
      const href = uri + this.base64(template)
      const fileName = coin.kindName
      let link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute('download', `${fileName}.xls`);
      link.click();
      // window.location.href = uri + this.base64(template);
    },
    base64(s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    // 分页
    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.searchParams.pageNum = current;
      this.searchParams.pageSize = pageSize;
      this.getData(this.searchParams);
    },
    changeVersionlist(id) {
      this.$set(this.searchParams, "kindId", id)
      this.getYesterdayPrice()
    },
    // 大币种列表
    async getSelectList() {
      const res = await this.axios(
        "/dq_admin/coinSilverVersionGoods/getSliverKind"
      );
      if (res.status == 200) {
        const { data } = res;
        this.biglist = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pages{
  overflow-y: scroll;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pages" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c(
          "a-select",
          {
            staticStyle: { margin: "5px", width: "150px" },
            attrs: {
              dropdownMatchSelectWidth: false,
              allowClear: "",
              size: "small",
              placeholder: "请选择大币种"
            },
            on: { select: _vm.changeVersionlist },
            model: {
              value: _vm.searchParams.kindId,
              callback: function($$v) {
                _vm.$set(_vm.searchParams, "kindId", $$v)
              },
              expression: "searchParams.kindId"
            }
          },
          _vm._l(_vm.biglist, function(items) {
            return _c(
              "a-select-option",
              { key: items.kindId, attrs: { value: items.kindId } },
              [_vm._v(_vm._s(items.kindName))]
            )
          }),
          1
        ),
        _c(
          "a-button",
          {
            staticStyle: { "margin-left": "10px" },
            attrs: { type: "primary", size: "small" },
            on: {
              click: function($event) {
                return _vm.exportData()
              }
            }
          },
          [_vm._v("导出数据")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "body" },
      [
        _c("a-table", {
          attrs: {
            pagination: _vm.pagination,
            columns: _vm.columns,
            "data-source": _vm.list,
            rowKey: function(record, n) {
              return record.id || n
            }
          },
          on: { change: _vm.changePage }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }